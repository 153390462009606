import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SinglePage from './pages/SinglePage';
import Navbar from './components/Navbar';
import ResultsPage from './pages/ResultsPage';
import ExploreStrategiesPage from './pages/ExploreStrategiesPage';
import LearnMorePage from './pages/LearnMorePage';
import Footer from './components/Footer';


function App() {
  return (
    <Router>
      <div>
        {/* Navbar */}
        <Navbar />

        {/* Routes */}
        <Routes>
          <Route path="/" element={<SinglePage />} />
          <Route path="/learn-more" element={<LearnMorePage />} />
          <Route path="/explore-strategies" element={<ExploreStrategiesPage />} />
          <Route path="/results-we-deliver" element={<ResultsPage />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
