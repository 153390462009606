import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import lMore from '../../assets/learnMore.jpeg';

const LearnMorePage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
        <Helmet>
            <title>Our Approach | Lexor Strategies</title>
            <meta
            name="description"
            content="Explore Lexor Strategies' unique approach to empowering businesses with strategic solutions for growth and long-term success."
            />
        </Helmet>

      {/* Title Section */}
      <div className="w-full py-16 bg-gray-100 md:px-24">
        <div className="mx-auto px-4 md:px-24">
          <h3 className="text-sm font-semibold text-gray-500 mb-2 tracking-widest uppercase">
            OUR APPROACH
          </h3>
          <h2 className="text-6xl font-bold text-black mb-6 leading-tight max-w-3xl">
            A Strategic Pathway to Unmatched Growth
          </h2>
          <p className="text-lg text-gray-700 leading-relaxed mb-8 max-w-2xl">
            At Lexor Strategies, we empower you to lead with clarity and confidence in a rapidly evolving market. Our focus isn’t just on providing solutions—it's about delivering a roadmap that drives growth, innovation, and long-term success.
          </p>
        </div>
      </div>

      {/* Main Content Layout */}
      <div className="w-full py-20 bg-white md:px-24">
        <div className="w-full mx-auto flex flex-col md:flex-row items-start gap-16 px-4 md:px-24">
            {/* Image Section */}
            <div className="w-full md:w-1/2 h-[500px] md:h-[600px] overflow-hidden rounded-lg shadow-lg">
                <img 
                src={lMore} 
                alt="Strategic Growth" 
                className="w-full h-full object-cover transition-transform duration-500 hover:scale-105"
                />
            </div>

            {/* Detailed SB7 Content */}
            <div className="w-full md:w-1/2 space-y-10">
                {/* Character */}
                <div>
                <h3 className="text-3xl font-bold text-gray-800 mb-4">
                    We Know Your Journey
                </h3>
                <p className="text-lg text-gray-700 leading-relaxed">
                    You’re a leader who values tangible results and measurable growth. You’re not just looking for strategies—you want to see results that align with your vision and the evolving demands of the industry.
                </p>
                </div>

                {/* Problem */}
                <div>
                <h3 className="text-3xl font-bold text-gray-800 mb-4">
                    Navigating Complex Challenges
                </h3>
                <p className="text-lg text-gray-700 leading-relaxed">
                    Whether it’s aligning your operations with cutting-edge technology or finding new ways to engage your market, the path forward can be unclear. We ensure clarity and focus while protecting your competitive edge.
                </p>
                </div>

                {/* Plan */}
                <div>
                <h3 className="text-3xl font-bold text-gray-800 mb-4">
                    A Proven Framework for Success
                </h3>
                <p className="text-lg text-gray-700 leading-relaxed">
                    Our proprietary approach combines strategic insights with actionable steps, moving from planning to implementation. But the exact nature of our strategies remains exclusive to you—customized to your unique needs and kept confidential to maintain your competitive advantage.
                </p>
                </div>

                {/* Call to Action */}
                <div className="flex flex-col items-center space-y-4 mt-8 w-full">
                    <Link 
                        to="/#contact"
                        className="w-full border border-solid text-black py-4 px-12 font-semibold text-lg transition-all hover:bg-gray-200 text-center rounded-md"
                    >
                        Join Our Journey
                    </Link>
                    <Link 
                        to="/explore-strategies"
                        className="w-full border border-blue-600 text-blue-600 py-4 px-12 font-semibold text-lg transition-all hover:bg-blue-600 hover:text-white text-center rounded-md"
                    >
                        Explore Our Strategies
                    </Link>
                    <Link 
                        to="/results-we-deliver"
                        className="w-full text-gray-700 py-4 px-12 font-semibold text-lg transition-all hover:text-blue-600 text-center rounded-md"
                    >
                        See the Results We Deliver
                    </Link>
                </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMorePage;
