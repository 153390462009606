import React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebook, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
// import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-12">
      <div className="container mx-auto px-4 md:px-24">
        {/* Top Section with Logo and Social Icons */}
        <div className="flex flex-col md:flex-row justify-between items-center mb-8">
          {/* Logo */}
          <div className="text-2xl font-bold mb-4 md:mb-0">
            Lexor <span className="text-blue-500">Strategies</span>
          </div>

          {/* Social Icons */}
          <div className="flex space-x-6">
            {/* <a href="https://facebook.com" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} className="text-xl hover:text-blue-400 transition duration-300" />
            </a>
            <a href="https://twitter.com" aria-label="Twitter" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitter} className="text-xl hover:text-blue-400 transition duration-300" />
            </a>
            <a href="https://linkedin.com" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="text-xl hover:text-blue-400 transition duration-300" />
            </a>
            <a href="https://instagram.com" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="text-xl hover:text-blue-400 transition duration-300" />
            </a> */}
          </div>
        </div>

        {/* Middle Section with Navigation Links */}
        <div className="flex flex-col md:flex-row justify-between mb-8">
          {/* <div className="flex flex-col mb-4 md:mb-0">
            <h4 className="text-lg font-semibold mb-2">Company</h4>
            <a href="/about" className="text-gray-400 hover:text-white transition duration-300">About Us</a>
            <a href="/careers" className="text-gray-400 hover:text-white transition duration-300">Careers</a>
            <a href="/blog" className="text-gray-400 hover:text-white transition duration-300">Blog</a>
            <a href="/contact" className="text-gray-400 hover:text-white transition duration-300">Contact</a>
          </div>
          <div className="flex flex-col mb-4 md:mb-0">
            <h4 className="text-lg font-semibold mb-2">Services</h4>
            <a href="/services/strategy" className="text-gray-400 hover:text-white transition duration-300">Strategy Consulting</a>
            <a href="/services/tech" className="text-gray-400 hover:text-white transition duration-300">Tech Consulting</a>
            <a href="/services/marketing" className="text-gray-400 hover:text-white transition duration-300">Marketing Solutions</a>
            <a href="/services/custom" className="text-gray-400 hover:text-white transition duration-300">Custom Solutions</a>
          </div>
          <div className="flex flex-col mb-4 md:mb-0">
            <h4 className="text-lg font-semibold mb-2">Resources</h4>
            <a href="/resources/faq" className="text-gray-400 hover:text-white transition duration-300">FAQs</a>
            <a href="/resources/guide" className="text-gray-400 hover:text-white transition duration-300">Getting Started Guide</a>
            <a href="/resources/help-center" className="text-gray-400 hover:text-white transition duration-300">Help Center</a>
            <a href="/privacy-policy" className="text-gray-400 hover:text-white transition duration-300">Privacy Policy</a>
          </div> */}
        </div>

        {/* Bottom Section with Contact Info */}
        <div className="flex flex-col md:flex-row justify-between items-center mt-8 border-t border-gray-700 pt-8">
          {/* Contact Information */}
          {/* <div className="flex items-center text-gray-400 space-x-4 mb-4 md:mb-0">
            <FontAwesomeIcon icon={faEnvelope} />
            <span>contact@lexorstrategies.com</span>
            <FontAwesomeIcon icon={faPhone} />
            <span>+1 (646) 887 7772 </span>
          </div> */}

          {/* Copyright */}
          <div className="text-gray-400">
            © {new Date().getFullYear()} Lexor Strategies. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
