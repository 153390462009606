import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For desktop dropdown
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false); // For mobile dropdown
  let timerId;

  const toggleMenu = () => setIsOpen(!isOpen);
  const toggleMobileDropdown = () => setIsMobileDropdownOpen(!isMobileDropdownOpen); // Toggles mobile dropdown

  // Open dropdown with a delay to keep it open while hovering
  const handleMouseEnter = () => {
    clearTimeout(timerId); // Clear any active timers
    setIsDropdownOpen(true); // Open the dropdown immediately on hover
  };

  // Set a timer to close the dropdown when the mouse leaves
  const handleMouseLeave = () => {
    timerId = setTimeout(() => {
      setIsDropdownOpen(false);
    }, 300); // Adjust this delay (300ms) as needed
  };

  return (
    <nav className="bg-white shadow-md relative font-roboto">
      <div className="container mx-auto px-4 py-4 flex justify-between items-center md:px-16">
        {/* Logo Section */}
        <div className="text-lg font-extrabold text-black leading-none">
          <a href="/" className="flex flex-col">
            <span className="uppercase tracking-wide">Lexor</span>
            <span className="uppercase tracking-wide">Strategies</span>
          </a>
        </div>

        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-12 items-center">
          {/* Dropdown Menu for "What We Do" */}
          <div
            className="relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <a
              href="/#WhatWeDo"
              className="text-md text-gray-800 font-semibold hover:text-blue-600 transition duration-300 cursor-pointer flex items-center"
            >
              What We Do <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-sm" />
            </a>

            {/* Dropdown Content */}
            {isDropdownOpen && (
              <div className="absolute left-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                <a
                  href="/learn-more"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition"
                >
                  &gt; Our Approach
                </a>
                <a
                  href="/explore-strategies"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition"
                >
                    &gt; Strategies
                </a>
                <a
                  href="/results-we-deliver"
                  className="block px-4 py-2 text-gray-800 hover:bg-gray-100 transition"
                >
                  &gt; Results
                </a>
              </div>
            )}
          </div>

          <a
            href="/#about"
            className="text-md text-gray-800 font-semibold hover:text-blue-600 transition duration-300 cursor-pointer hover:border-b-2 hover:border-blue-600 pb-1"
          >
            Our Firm
          </a>
          <a
            href="/#contact"
            className="text-md text-gray-800 border border-black px-6 py-2 rounded-md font-semibold hover:text-blue-600 transition duration-300 cursor-pointer hover:border-blue-600 hover:bg-gray-200"
          >
            Join Us
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-gray-600 focus:outline-none">
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} className="text-xl" />
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-white border-t">
          {/* Mobile What We Do with Dropdown */}
          <div className="flex justify-between items-center px-4 py-4 hover:bg-gray-100">
            <a
              href="/#WhatWeDo"
              className="block text-gray-800 hover:text-blue-600 transition duration-300"
              onClick={toggleMenu}
            >
              What We Do
            </a>
            <button onClick={toggleMobileDropdown} className="focus:outline-none">
              <FontAwesomeIcon
                icon={isMobileDropdownOpen ? faChevronUp : faChevronDown}
                className="text-gray-600"
              />
            </button>
          </div>

          {/* Mobile Dropdown Links */}
          {isMobileDropdownOpen && (
            <div className="space-y-2">
              <a
                href="/learn-more"
                className="block text-gray-800 px-4 py-2 hover:bg-gray-100 transition duration-300"
                onClick={toggleMenu}
              >
                &gt; Our Approach
              </a>
              <a
                href="/explore-strategies"
                className="block text-gray-800 px-4 py-2 hover:bg-gray-100 transition duration-300"
                onClick={toggleMenu}
              >
                &gt; Strategies
              </a>
              <a
                href="/results-we-deliver"
                className="block text-gray-800 px-4 py-2 hover:bg-gray-100 transition duration-300"
                onClick={toggleMenu}
              >
                &gt; Results
              </a>
            </div>
          )}

          {/* Other Links */}
          <a
            href="/#about"
            className="block text-gray-800 px-4 py-4 hover:bg-gray-100 hover:text-blue-600 transition duration-300"
            onClick={toggleMenu}
          >
            Our Firm
          </a>
          <a
            href="/#contact"
            className="block text-gray-800 px-4 py-4 hover:bg-gray-100 hover:text-blue-600 transition duration-300"
            onClick={toggleMenu}
          >
            Join Us
          </a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
