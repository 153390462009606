import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet-async';
import { faBullseye, faLightbulb, faCogs } from '@fortawesome/free-solid-svg-icons';

const ExploreStrategiesPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
        <Helmet>
            <title>Explore Strategies | Lexor Strategies</title>
            <meta
            name="description"
            content="Discover Lexor Strategies' custom solutions and consulting services designed to foster growth and provide a competitive edge."
            />
        </Helmet>
      
      {/* Header Section */}
      <header className="py-20 px-6 md:px-24 bg-gray-100">
        <div className="mx-auto md:px-24">
          <h3 className="text-sm font-semibold text-gray-500 tracking-widest uppercase mb-2">
            OUR STRATEGY
          </h3>
          <h2 className="text-6xl font-bold text-gray-900">
            Strategic Solutions Tailored for Growth
          </h2>
          <br />
          <p className="text-lg text-gray-700 mx-auto">
            We design each strategy to empower you in the market, ensuring sustainable growth and a competitive edge.
          </p>
        </div>
      </header>


      {/* Strategy Sections */}
      <section className="py-20 px-6 md:px-12 mx-auto space-y-12 md:px-24">
        
        {/* Strategic Messaging Section */}
        <div className="bg-white shadow-lg rounded-xl p-8 md:p-12 hover:shadow-2xl transition-shadow md:px-24">
          <FontAwesomeIcon icon={faBullseye} className="text-blue-500 text-5xl mb-4" />
          <h3 className="text-3xl font-semibold text-gray-900 mb-4">Strategic Messaging</h3>
          <p className="text-gray-700 leading-relaxed mb-6">
            Deliver impactful messages that resonate with your audience, reinforce your brand values, and set you apart.
          </p>
          <ul className="text-lg text-gray-700 space-y-4 pl-6 list-disc">
            <li><strong>Brand Storytelling:</strong> Shape your brand’s narrative to establish a lasting connection with customers.</li>
            <li><strong>Audience Engagement:</strong> Foster genuine engagement to build a loyal customer base.</li>
            <li><strong>Positioning Expertise:</strong> Elevate your brand’s position by focusing on its unique strengths.</li>
          </ul>
        </div>

        {/* Tech Consulting Section */}
        <div className="bg-white shadow-lg rounded-xl p-8 md:p-12 hover:shadow-2xl transition-shadow md:px-24">
          <FontAwesomeIcon icon={faLightbulb} className="text-blue-500 text-5xl mb-4" />
          <h3 className="text-3xl font-semibold text-gray-900 mb-4">Tech Consulting</h3>
          <p className="text-gray-700 leading-relaxed mb-6">
            Utilize our tech expertise to streamline processes, optimize resources, and prepare for future growth.
          </p>
          <ul className="text-lg text-gray-700 space-y-4 pl-6 list-disc">
            <li><strong>Process Optimization:</strong> Identify and enhance key workflows to boost efficiency and reduce costs.</li>
            <li><strong>Scalable Solutions:</strong> Set up a robust tech infrastructure to support sustainable growth.</li>
            <li><strong>Roadmapping:</strong> Create a technology roadmap aligned with your strategic business objectives.</li>
          </ul>
        </div>

        {/* Custom Tech Solutions Section */}
        <div className="bg-white shadow-lg rounded-xl p-8 md:p-12 hover:shadow-2xl transition-shadow md:px-24">
          <FontAwesomeIcon icon={faCogs} className="text-blue-500 text-5xl mb-4" />
          <h3 className="text-3xl font-semibold text-gray-900 mb-4">Custom Tech Solutions</h3>
          <p className="text-gray-700 leading-relaxed mb-6">
            Innovate and excel with tech solutions tailored to your unique business challenges.
          </p>
          <ul className="text-lg text-gray-700 space-y-4 pl-6 list-disc">
            <li><strong>Software Development:</strong> Build customized software to address specific operational needs.</li>
            <li><strong>Automation Integration:</strong> Introduce automation for improved productivity and minimized errors.</li>
            <li><strong>Data-Driven Applications:</strong> Utilize data to make informed decisions that align with strategic goals.</li>
          </ul>
        </div>
      </section>

      {/* Call to Action */}
      <div className="bg-blue-600 text-white py-16 px-6 md:px-12 text-center">
        <h3 className="text-4xl font-bold mb-4">Ready to See the Results?</h3>
        <p className="text-lg mb-8">Discover how our strategies have helped clients thrive and achieve measurable success.</p>
        <Link 
          to="/results-we-deliver"
          className="inline-block bg-white text-blue-600 py-4 px-12 rounded-lg font-semibold text-lg shadow-lg hover:shadow-xl transition-all"
        >
          See the Results We Deliver
        </Link>
      </div>
    </div>
  );
};

export default ExploreStrategiesPage;
