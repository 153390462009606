import React, { useState, useEffect } from 'react';
import { InlineWidget } from 'react-calendly';
import { ToastContainer, toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { Helmet } from 'react-helmet-async';
import 'react-toastify/dist/ReactToastify.css';
import home1 from '../../assets/home1.jpeg';
import home2 from '../../assets/home2.jpeg';
import home3 from '../../assets/home3.jpeg';
import home4 from '../../assets/home4.jpeg';
import home5 from '../../assets/home5.jpeg';
import aboutUs from '../../assets/aboutUs.jpeg';
import clearplan from '../../assets/clearplan.jpeg';
import result from '../../assets/result.jpeg';
import understand from '../../assets/understand.jpeg';
import { db } from '../../api/firebaseConfig';
import { collection, addDoc } from "firebase/firestore";

// Placeholder for service options
const serviceOptions = [
  'Strategic Messaging',
  'Tech Consulting',
  'Custom Tech Solutions',
  'SEO & Content Strategy',
  'Brand Development',
  'Audience Engagement',
  'Paid Advertising',
];

const tabData = [
  {
    title: 'Strategic Messaging',
    content: [
      {
        title: 'Brand Storytelling',
        description: 'Create impactful narratives that reflect your brand’s core values and foster deep connections with your audience.',
      },
      {
        title: 'Content Strategy',
        description: 'Craft data-driven content strategies to elevate brand awareness, engage customers, and enhance digital presence.',
      },
      {
        title: 'Market Positioning',
        description: 'Analyze and define your market positioning to establish a competitive advantage and appeal to target audiences.',
      },
    ],
  },
  {
    title: 'Paid Advertising',
    content: [
      {
        title: 'Targeted Ad Campaigns',
        description: 'Create and manage ad campaigns across Google, Facebook, and other platforms tailored to reach your audience and maximize ROI.',
      },
      {
        title: 'Budget Optimization',
        description: 'Optimize ad spend by analyzing data to ensure every dollar is well spent, delivering the best returns.',
      },
      {
        title: 'A/B Testing & Analytics',
        description: 'Conduct A/B testing to refine ad performance and deliver insights that inform better strategies and growth.',
      },
    ],
  },
  {
    title: 'Tech Consulting',
    content: [
      {
        title: 'Process Optimization',
        description: 'Analyze existing workflows to identify inefficiencies and recommend changes that improve productivity.',
      },
      {
        title: 'Infrastructure Assessment',
        description: 'Evaluate your current tech setup to identify strengths, weaknesses, and areas for sustainable growth.',
      },
      {
        title: 'Tech Roadmapping',
        description: 'Develop strategic plans for technology adoption, focusing on aligning tech initiatives with business objectives.',
      },
    ],
  },
  {
    title: 'Custom Tech Solutions',
    content: [
      {
        title: 'Software Development',
        description: 'Develop tailored software solutions that address unique business needs and drive digital transformation.',
      },
      {
        title: 'Workflow Automation',
        description: 'Create automation solutions that streamline processes, minimize manual work, and enhance operational efficiency.',
      },
      {
        title: 'Custom Application Design',
        description: 'Build bespoke applications tailored to your business requirements, improving user experiences and functionality.',
      },
    ],
  },
];


// Array of images for the transition
const images = [home1, home2, home3, home4, home5];

const SinglePage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    businessName: '',
    description: '',
    services: [],
    additional: '',
  });
  const [currentImage, setCurrentImage] = useState(0);
  const [fade, setFade] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = location.hash.replace('#', '');
      scroller.scrollTo(section, {
        smooth: true,
        duration: 1000,
        offset: 0, // Adjust offset for fixed navbar
      });
    }
  }, [location]);

  // Image transition effect
  useEffect(() => {
    const imageInterval = setInterval(() => {
      setFade(true);
      setTimeout(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        setFade(false);
      }, 1000); // Fade duration
    }, 4000); // Image duration

    return () => clearInterval(imageInterval);
  }, []);

  // Form input changes
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Checkbox changes
  const handleCheckboxChange = (service) => {
    setFormData((prev) => {
      const isChecked = prev.services.includes(service);
      return {
        ...prev,
        services: isChecked
          ? prev.services.filter((item) => item !== service)
          : [...prev.services, service],
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      await addDoc(collection(db, "submissions"), {
        fullName: formData.fullName,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        businessName: formData.businessName,
        description: formData.description,
        services: formData.services,
        additional: formData.additional,
        submittedAt: new Date()
      });
      toast.success('Your information has been submitted successfully!');
      
      // Reset the form data
      setFormData({
        fullName: '',
        email: '',
        phoneNumber: '',
        businessName: '',
        description: '',
        services: [],
        additional: '',
      });
    } catch (error) {
      console.error("Error saving document: ", error);
      toast.error('There was an error submitting your information. Please try again.');
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>Home | Lexor Strategies</title>
        <meta
          name="description"
          content="Discover Lexor's strategic solutions that empower businesses to achieve extraordinary results in messaging, tech consulting, and custom solutions."
        />
      </Helmet>

  {/* Hero Section */}
  <header className="relative h-screen flex items-end bg-black">
    {/* Background Image */}
    <img
      key={currentImage}
      src={images[currentImage]}
      alt="Header Background"
      className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 ${fade ? 'opacity-0' : 'opacity-60'}`}
    />

    {/* Dark Overlay */}
    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-30"></div>

    {/* Content */}
    <div className="absolute bottom-1/4 left-0 px-4 md:px-24 text-left z-1">
      <h1 className="text-6xl md:text-8xl font-extrabold text-white mb-4 leading-tight">
        Drive Success <br /> With Lexor.
      </h1>
      <p className="text-xl md:text-2xl text-gray-200 mb-8 max-w-4xl">
        We help you achieve extraordinary results through strategic messaging, 
        tech consulting, and tailored solutions—transforming your vision into reality.
      </p>
      <a 
        href="#contact" 
        className="border text-white py-4 px-8 rounded-full font-semibold hover:bg-gray-500 transition-all shadow-lg"
      >
        Start Your Journey
      </a>
    </div>
  </header>


  {/* About Us Section */}
  <section id="about" className="bg-gray-50 py-24 px-6 md:px-24">
    {/* Title Heading */}
    <div className="w-full mb-12">
      <h3 className="text-sm font-semibold text-gray-500 mb-2 tracking-widest uppercase">
        Our Story
      </h3>
      <h2 className="text-5xl md:text-6xl font-bold text-black mb-8 leading-tight max-w-4xl">
        Empowering Innovation and Lasting Impact
      </h2>
    </div>

    {/* Content with Image */}
    <div className="w-full mx-auto grid grid-cols-1 md:grid-cols-12 gap-10 items-center">
      {/* Image Column */}
      <div className="md:col-span-6 flex items-center">
        <div className="relative w-full h-[550px] rounded-lg overflow-hidden shadow-lg">
          <img 
            src={aboutUs}
            alt="Team Collaboration" 
            className="w-full h-full object-cover transition-transform duration-500 hover:scale-105"
          />
        </div>
      </div>

      {/* Text Column */}
      <div className="md:col-span-6 space-y-12">
        {/* Mission Block */}
        <div className="space-y-4">
          <h3 className="text-4xl font-bold text-gray-800 mb-4">
            Our Mission
          </h3>
          <p className="text-lg text-gray-600 leading-relaxed">
            We empower businesses and individuals by delivering strategic solutions and advanced technology. Our mission is to drive growth, foster innovation, and create meaningful impact in every endeavor.
          </p>
        </div>

        {/* Core Values Block */}
        <div className="space-y-6">
          <h3 className="text-4xl font-bold text-gray-800 mb-4">
            Our Core Values
          </h3>
          <ul className="space-y-6">
            {[
              { 
                title: 'Integrity', 
                description: 'We hold ourselves to the highest standards, ensuring ethical and transparent actions in every aspect of our work.' 
              },
              { 
                title: 'Innovation', 
                description: 'Our approach fosters creativity and embraces forward-thinking to achieve excellence in all that we do.' 
              },
              { 
                title: 'Client-Centric', 
                description: 'Our clients success is our driving force, inspiring us to go above and beyond to support their growth.' 
              },
            ].map((value, idx) => (
              <li key={idx} className="flex items-start space-x-4">
                {/* Arrow Icon */}
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="w-6 h-6 text-blue-500 flex-shrink-0 mt-1" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
                <div className="text-lg text-gray-700">
                  <strong>{value.title}:</strong> {value.description}
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Vision Block & CTA */}
        <div className="space-y-4">
          <h3 className="text-4xl font-bold text-gray-800 mb-4">
            Our Vision
          </h3>
          <p className="text-lg text-gray-600 leading-relaxed">
            We envision a world where businesses thrive through strategic innovation, adaptability, and a relentless pursuit of growth. We aim to turn challenges into opportunities and set new benchmarks for success.
          </p>
          <div className="mt-8">
            <a 
              href="#contact"
              className="inline-block border border-black border-solid text-black py-3 px-10 rounded-md font-semibold text-lg transition-all hover:bg-gray-200"
            >
              Join Our Journey
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  {/* Our Focus */}
  <section id="ourFocus" className="bg-white py-20 px-4 md:px-24">
    {/* Title Heading */}
    <div className="w-full px-4 md:px-6 md:py-8 mb-12 md:mb-16">
      <h3 className="text-sm font-semibold text-gray-500 mb-2 tracking-widest uppercase">
        OUR FOCUS
      </h3>
      <h2 className="text-6xl font-bold text-black mb-8 leading-tight max-w-3xl">
        Empowering Your Growth with Strategic Solutions
      </h2>
    </div>

    {/* Three-Column Layout */}
    <div className="w-full mx-auto grid grid-cols-1 md:grid-cols-3 gap-12">
      {/* Column 1 */}
      <div className="flex flex-col items-start space-y-4">
        <img 
          src={understand} 
          alt="Excellence" 
          className="w-full h-56 object-cover rounded-lg shadow-lg mb-4"
        />
        <h3 className="text-2xl font-semibold text-gray-900">
          Understanding Your Challenges
        </h3>
        <p className="text-lg text-gray-700">
          We recognize the rapid changes in today's business landscape, from digital disruption to evolving customer demands. Our commitment is to align our solutions with your unique challenges, driving growth and efficiency.
        </p>
        <a href="/learn-more" className="text-blue-600 font-semibold mt-4">
          Learn More About Our Approach
        </a>
      </div>

      {/* Column 2 */}
      <div className="flex flex-col items-start space-y-4">
        <img 
          src={clearplan} 
          alt="Strategy" 
          className="w-full h-56 object-cover rounded-lg shadow-lg mb-4"
        />
        <h3 className="text-2xl font-semibold text-gray-900">
          Creating a Clear Plan
        </h3>
        <p className="text-lg text-gray-700">
          Our strategy is built on a deep understanding of your industry, market trends, and customer needs. We craft a strategic plan that is not only actionable but also adaptable to changing business dynamics.
        </p>
        <a href="/explore-strategies" className="text-blue-600 font-semibold mt-4">
          Explore Our Strategies
        </a>
      </div>

      {/* Column 3 */}
      <div className="flex flex-col items-start space-y-4">
        <img 
          src={result} 
          alt="Impact" 
          className="w-full h-56 object-cover rounded-lg shadow-lg mb-4"
        />
        <h3 className="text-2xl font-semibold text-gray-900">
          Achieving Real Results
        </h3>
        <p className="text-lg text-gray-700">
          We measure success through tangible results: increased efficiency, higher profitability, and enhanced customer engagement. Our data-driven approach ensures that every solution delivers measurable impact.
        </p>
        <a href="/results-we-deliver" className="text-blue-600 font-semibold mt-4">
          See the Results We Deliver
        </a>
      </div>
    </div>

      {/* Call to Action */}
      {/* <div className="mt-16 bg-gradient-to-r from-blue-500 to-blue-700 text-white py-12 px-8 md:px-16 rounded-xl shadow-lg flex flex-col md:flex-row items-center justify-between">
        <div className="text-center md:text-left mb-6 md:mb-0">
          <h3 className="text-4xl font-bold mb-3">
            Ready to Start Your Journey with Us?
          </h3>
          <p className="text-lg max-w-md">
            Join us in transforming your challenges into opportunities for success.
          </p>
        </div>
        <div className="flex-shrink-0">
          <a 
            href="#sign-up"
            className="inline-block bg-white text-blue-600 py-4 px-10 rounded-full font-semibold text-lg shadow-md hover:bg-gray-100 transition-all"
          >
            Get Started
          </a>
        </div>
      </div> */}
  </section>

  <section id="WhatWeDo" className="bg-gray-50 py-20 px-4 md:px-24">
      {/* Title Heading */}
      <div className="w-full px-4 md:px-6 md:py-8 mb-8 md:mb-0">
          <h3 className="text-sm font-semibold text-gray-500 mb-2 tracking-widest uppercase">
            WHAT WE DO
          </h3>
          <h2 className="text-5xl font-bold text-black mb-8 leading-tight">
            Delivering for Our Clients
          </h2>
      </div>

      <div className="mx-auto flex flex-col md:flex-row">
        {/* Main Layout */}
        <div className="w-full md:w-1/4 px-2 md:px-4 flex-shrink-0 mb-8 md:mb-0">
          {/* Vertical Tab Navigation */}
          <div className="space-y-4">
            {tabData.map((tab, index) => (
              <button
                key={index}
                className={`w-full text-left py-3 text-lg font-medium transition-all border-l-4 ${
                  activeTab === index
                    ? 'border-blue-500 text-blue-600 pl-4'
                    : 'border-transparent text-gray-700 hover:text-blue-500 pl-4'
                }`}
                onClick={() => setActiveTab(index)}
              >
                {tab.title}
              </button>
            ))}
          </div>
        </div>

        {/* Tab Content */}
        <div className="w-full md:w-3/4">
          {tabData[activeTab].content.map((item, idx) => (
            <div key={idx} className="mb-8 border-b pb-4">
              <h4 className="text-2xl font-semibold text-gray-800 mb-2">
                {item.title}
              </h4>
              <p className="text-lg text-gray-600">
                {item.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Banner */}
      <div className="mt-16 bg-gradient-to-r from-blue-600 to-blue-500 text-white py-12 px-8 md:px-16 rounded-xl shadow-lg">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* CTA Content */}
          <div className="text-center md:text-left mb-6 md:mb-0">
            <h3 className="text-4xl font-bold mb-3">
              Unlock Your Business Potential
            </h3>
            <p className="text-lg max-w-md">
              Discover how our strategic solutions can help your company achieve growth, efficiency, and innovation.
            </p>
          </div>

          {/* Call to Action */}
          <div className="flex-shrink-0">
            <a
              href="/#contact" // Adjust to your specific sign-up link or page
              className="inline-block bg-white text-blue-600 py-4 px-10 rounded-full font-semibold text-lg shadow-md hover:bg-gray-100 transition-all"
            >
              Get Started Today
            </a>
          </div>
        </div>
      </div>
    </section>



  {/* Contact Section */}
  <section id="contact" className="bg-gray-100 py-4">
    <div className="w-full px-4 md:px-10 mx-auto text-left md:px-24">
      {/* Section Header */}
      <h3 className="text-sm font-semibold text-gray-500 mb-2 tracking-widest uppercase">
        Get Started
      </h3>
      <h2 className="text-5xl font-bold text-gray-800 mb-8 leading-tight">
        Let's Discuss Your Goals
      </h2>

      <div className="flex flex-col lg:flex-row gap-12">
        {/* Contact Form */}
        <div className="flex-1 bg-white p-8 rounded-lg shadow-md">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Full Name */}
            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">
                Full Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>

            {/* Email Address */}
            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">
                Email Address<span className="text-red-500">*</span>
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>

            {/* Phone Number */}
            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">
                Phone Number<span className="text-red-500">*</span>
              </label>
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>

            {/* Company / Business Name */}
            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">
                Company / Business Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="businessName"
                value={formData.businessName}
                onChange={handleInputChange}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                required
              />
            </div>

            {/* Services Needed */}
            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">
                Services Needed<span className="text-red-500">*</span>
              </label>
              <div className="space-y-2">
                {serviceOptions.map((service, idx) => (
                  <label key={idx} className="flex items-center text-gray-700">
                    <input
                      type="checkbox"
                      name="services"
                      value={service}
                      checked={formData.services.includes(service)}
                      onChange={() => handleCheckboxChange(service)}
                      className="mr-2 h-5 w-5 text-blue-500 border-gray-300 rounded focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                    {service}
                  </label>
                ))}
              </div>
            </div>

            {/* Project Description */}
            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">
                Project Description / Goals<span className="text-red-500">*</span>
              </label>
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                rows="4"
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="Describe your project goals, needs, and expectations"
                required
              ></textarea>
            </div>

            {/* Additional Information */}
            <div>
              <label className="block text-lg font-medium text-gray-700 mb-2">
                Additional Information or Questions
              </label>
              <textarea
                name="additional"
                value={formData.additional}
                onChange={handleInputChange}
                rows="3"
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="Any other details you'd like to share"
              ></textarea>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full py-4 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition-all"
            >
              Submit
            </button>
          </form>
        </div>

        {/* Calendly Integration */}
        <div className="flex-1 w-full p-6 rounded-xl shadow-md bg-white">
          <InlineWidget
            url="https://calendly.com/marcus-chau-albuddy/chat"
            styles={{ height: '800px', width: '100%' }}  // Increased height here
            pageSettings={{
              backgroundColor: 'ffffff',
              hideEventTypeDetails: false,
              hideLandingPageDetails: false,
              primaryColor: '00a2ff',
              textColor: '4d5055',
            }}
          />
        </div>
      </div>
    </div>
  </section>


  <ToastContainer position="top-right" />
</div>

  );
};

export default SinglePage;
