import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const ResultsPage = () => {
  return (
    <div className="min-h-screen bg-gray-50">
        <Helmet>
            <title>Results We Deliver | Lexor Strategies</title>
            <meta
            name="description"
            content="Discover the tangible results and proven impact that Lexor Strategies delivers for clients through strategic solutions."
            />
        </Helmet>
      
      {/* Header Section */}
      <header className="py-20 px-6 md:px-24 bg-gray-100">
        <div className="mx-auto md:px-24">
          <h3 className="text-sm font-semibold text-gray-500 tracking-widest uppercase mb-2">
            RESULTS WE DELIVER
          </h3>
          <h1 className="text-6xl font-bold text-gray-900">
            Proven Impact with Tangible Results
          </h1>
        </div>
      </header>

      {/* Case Study Section */}
      <section className="bg-white py-20 px-6 md:px-24">
        <div className="mx-auto md:px-24">
          <h2 className="text-4xl font-bold text-gray-800 mb-4">Albuddy: From Vision to Reality</h2>
          <p className="text-lg text-gray-700 mx-auto mb-8">
            Through a strategic partnership with Albuddy, we enabled them to grow from a concept to a thriving platform. Our tailored approach helped them define their brand, optimize operations, and scale effectively in a competitive landscape.
          </p>
        </div>

        <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-12 mt-12 md:px-24">
          
          {/* Result 1 */}
          <div className="bg-gray-50 p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Increased Engagement</h3>
            <p className="text-lg text-gray-700 leading-relaxed">
              Through strategic messaging and enhanced brand positioning, Albuddy achieved a 50% increase in engagement across its social platforms, establishing a strong online presence.
            </p>
          </div>

          {/* Result 2 */}
          <div className="bg-gray-50 p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Operational Efficiency</h3>
            <p className="text-lg text-gray-700 leading-relaxed">
              Our tech consulting streamlined Albuddy's backend processes, reducing manual workload by 30% and enabling the team to focus more on growth-driven activities.
            </p>
          </div>

          {/* Result 3 */}
          <div className="bg-gray-50 p-8 rounded-lg shadow-lg">
            <h3 className="text-2xl font-semibold text-gray-800 mb-4">Revenue Growth</h3>
            <p className="text-lg text-gray-700 leading-relaxed">
              By optimizing their sales funnel and engagement strategies, Albuddy saw a 25% increase in monthly revenue, setting them on a path for sustainable growth.
            </p>
          </div>
        </div>
      </section>

      {/* Value Proposition Section */}
      <section className="bg-gray-50 py-20 px-6 md:px-24">
        <div className="mx-auto md:px-24">
            <h3 className="text-sm font-semibold text-gray-500 tracking-widest uppercase mb-2">
            RESULTS YOU CAN EXPECT
            </h3>
            <h2 className="text-4xl font-bold text-gray-800 mb-6">Empowering Your Success Through Proven Results</h2>
            <p className="text-lg text-gray-700 mx-auto mb-12">
                Our results are rooted in data-driven strategies, customized solutions, and a commitment to excellence. Whether you’re aiming to expand your brand, streamline operations, or increase revenue, Lexor Strategies delivers real, measurable outcomes tailored to your needs.
            </p>
            <Link 
                to="/explore-strategies"
                className="bg-blue-600 text-white py-4 px-10 rounded-lg font-semibold text-lg shadow-lg hover:shadow-xl transition-all"
            >
                Explore Our Strategies
            </Link>
        </div>
      </section>
    </div>
  );
};

export default ResultsPage;
